<template>
    <div class="full-screen">
      <FullView :details="detailsInfo" v-if="isShow"></FullView>
    </div>
  </template>
  
  <script>
  import { examFullViewDetails } from "@/utils/newDecorate";
  import FullView from "@/components/NewDecoratorFullScreen.vue";
  import { teacheropShopDecorationStuDet } from '@/utils/apis.js'
  
  export default {
    name: "FullScreen",
    components: {
      FullView,
    },
    props: {},
    data() {
      return {
        detailsInfo: {},
        isShow: false,
      };
    },
    computed: {},
    methods: {
      getDetails() {
        let params = {
                    exam_id: this.$route.query.exam_id,
                    user_id: this.$route.query.user_id,
                }
        teacheropShopDecorationStuDet(params).then(({ data, msg }) => {
            console.log(data,"kkk");
          this.detailsInfo = data;
          this.isShow = true;
        });
      },
    },
    mounted() {
      this.getDetails();
    },
  };
  </script>
  
  <style scoped lang="scss">
  .full-screen {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
  }
  </style>
  